module.exports = {
  created() {
    console.log(this.$store.state)
    let state = this.$store.state;
    for (let name in state) {
        // console.log('在这里存储', name, state[name])
        let value = state[name]
        this.$store.commit('$localStore', {
            name,
            value
          })
    }
  }
}
