// i18n计算
module.exports = {
  data() {
    return {
    }
  },
  computed: {
    // 计算属性，监听语言变化
    i18n() {
      // console.log("通过这里处理")
      return this.$t('message');
    },
    // 双语切换页面数据，，并修改页面title
    i18nData() {
      // console.log("通过这里处理", this.i18n, this.$route)
      if (this.$route) {
        let name = this.$route.name;
        if (name) {
          this.$store.state.metaInfo.title = this.i18n[name].name;
          return this.i18n[name];
        }
        return false
      }
      return false
    },
    // 当前选择语言
    locale() {
      return this.$i18n.locale;
    },
    baseUrl(){
      return this.$api.baseUrl
    } 
  },
  watch: {
    // 监测store.state
    i18nData(newVal, oldVal) {
      // console.log("i18nData发生改变");
    }
  },
  methods: {
    // 双语切换
    candeFn() {
      let locale = this.$i18n.locale;
      if (locale == "zh-CN") {
        this.$i18n.locale = "en-US";
        localStorage.setItem("locale", "en-US")
      } else {
        this.$i18n.locale = "zh-CN";
        localStorage.setItem("locale", "zh-CN")
      }
    },
    // getblocklist封装
    async getblocklist(type) {
      try {
        let params = {
          type: type
        };
        let res = await this.$api.https.getblocklist(params);
        // console.log("头部请求数字", res);
        if (!res.code) {
          this.$message({
            message: res.msg
          });
          return [];
        }
        return res.data;
      } catch (error) {
        console.log(err);
      }
    },
    // getblocklist封装
    async getpages(type) {
      try {
        let params = {
          name: type
        }
        let res = await this.$api.https.getpages(params);
        if (!res.code) {
          this.$message({
            message: res.msg
          });
          return [];
        }
        return res.data;
      } catch (err) {
        console.log(err);
      }
    },
     // getblock封装
     async getblock(type) {
      try {
        let params = {
          name: type
        }
        let res = await this.$api.https.getblock(params);
        if (!res.code) {
          this.$message({
            message: res.msg
          });
          return [];
        }
        return res.data;
      } catch (err) {
        console.log(err);
      }
    }
  },
}
