<template>
  <div id="index" class="index">
    <div class="loginBar" v-if="false">
      <div class="loginBox">
        <div class="loginBox_left"></div>
        <div class="loginBox_right">
          <div class="loginView" v-if="!isLogin">
            <div class="signBox">
              <el-button
                class="sign"
                :class="nowPage == 1 ? 'active' : ''"
                @click="toPage(0)"
                >{{ indexData.login }}</el-button
              >
            </div>
            <div class="signBox">
              <el-button
                class="sign"
                :class="nowPage == 2 ? 'active' : ''"
                @click="toPage(1)"
                >{{ indexData.register }}</el-button
              >
            </div>
          </div>
          <div class="loginView myInfo" v-else>
            <div
              class="notice"
              :class="nowPage == 3 ? 'active' : ''"
              @click="toPage(3)"
            >
              <i class="el-icon-message-solid"></i>
              <span>{{ indexData.message }}</span>
            </div>
            <div class="myAvatar" @click="toPage(3)">
              <img :src="vuex_user.avatar" alt="" />
            </div>
          </div>
          <div class="cande" :class="locale" @click="candeFn"></div>
        </div>
      </div>
    </div>
    <div class="loginBar">
      <div class="loginBox">
        <div class="loginBox_left"></div>
        <div class="loginBox_right">
          <span>环境污染物量子基因组数据库</span>
          <span>Environmental Pollutants Quantum Genome Database</span>
        </div>
      </div>
    </div>
    <div class="tabbarBox">
      <div class="tabbarFlex">
        <div class="tabbarLeft" @click="toPage(2)"></div>
        <el-menu
          :default-active="activeIndex"
          class="el-menu-demo tabbar"
          mode="horizontal"
          @select="handleSelect"
          background-color="#fff"
          text-color="#656A70"
          active-text-color="#0071BC"
          router
        >
          <el-menu-item index="/Home">
            <span>{{ tabbar[0].cname }}</span>
            <span>{{ tabbar[0].name }}</span>
          </el-menu-item>
          <el-menu-item index="/about">
            <span>{{ tabbar[1].cname }}</span>
            <span>{{ tabbar[1].name }}</span>
          </el-menu-item>
          <el-menu-item index="/database">
            <span>{{ tabbar[2].cname }}</span>
            <span>{{ tabbar[2].name }}</span>
          </el-menu-item>
          <el-menu-item index="/contact">
            <span>{{ tabbar[3].cname }}</span>
            <span>{{ tabbar[3].name }}</span>
          </el-menu-item>
          <!-- <el-menu-item index="/blogs">{{ indexData.tabbar[3] }}</el-menu-item> -->
        </el-menu>
        <div class="tabbarRight" v-if="!isLogin">
          <span @click="toPage(0)">登录</span>/
          <span @click="toPage(1)">注册</span>
        </div>
        <div class="tabbarRight myInfo" v-else>
          <div class="myAvatar" @click="toPage(3)">
            <img :src="vuex_user.avatar" alt="" />
          </div>
          <div
            class="notice"
            :class="nowPage == 3 ? 'active' : ''"
            @click="toPage(3)"
          >
            <span>{{ vuex_user.nickname }}</span>
            <span>{{ vuex_user.mobile || vuex_user.email || '' }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="routerBox">
      <div class="routerView">
        <router-view></router-view>
        <!-- <keep-alive max="10">
          <router-view v-if="$route.meta.keepAlive"></router-view>
        </keep-alive>
        <router-view v-if="!$route.meta.keepAlive"></router-view> -->
      </div>
    </div>
    <footerBar
      v-if="!footBarShow"
      :follow-pubchem-list="followPubchemList"
      @drawerFn="drawerFn"
    ></footerBar>
    <el-backtop></el-backtop>
    <!-- <el-drawer
      class="drawer"
      title="我是标题"
      :visible.sync="drawer"
      :with-header="false"
      direction="btt"
      :modal="false"
      size="12vh"
    >
      <div class="drawerBox" v-loading="!vuex_config">
        <div class="left"></div>
        <div class="right">
          <span>{{
            vuex_config.config
              ? vuex_config.config.sitename
              : "北京大学环境有机污染物分子的反应活性数据库"
          }}</span>
          <span>{{ vuex_config.config ? vuex_config.config.title : " " }}</span>
        </div>
      </div>
    </el-drawer> -->
  </div>
</template>

<script>
// 混入store本地存储
import vuexStore from "./../store/store.mixin.js";
import footerBar from "./../components/footerBar.vue";

export default {
  name: "index",
  mixins: [vuexStore],
  components: {
    footerBar
  },
  data() {
    return {
      drawer: true,
      followPubchemList: [],
      tabbar: [
        {
          cname: "首页",
          name: "Home"
        },
        {
          cname: "关于",
          name: "About"
        },
        {
          cname: "数据库",
          name: "Database"
        },
        {
          cname: "联系我们",
          name: "Contact"
        }
      ]
    };
  },
  async created() {
    // console.log("中英切换", this.i18nData);
    console.log("配置", this.$store.state);
    let res = await this.$api.https.getConfig();
    if (!res.code) {
      return;
    }
    this.$store.state.vuex_config = res.data;
    if (this.vuex_token) {
      this.getUserIndex();
    }
    // this.followPubchem();
  },
  methods: {
    drawerFn(flag) {
      this.drawer = flag;
    },
    // navmenu router动态选中
    // getNavType() {
    //   this.activeIndex = this.$store.state.activeIndex;
    //   //store.state.activeIndex里值变化的时候，设置navselected
    // },
    handleSelect(index) {
      this.$store.state.activeIndex = index;
      //按钮选中之后设置当前的index为store里的值。
    },
    toPage(index) {
      let pageArr = ["/login", "/register", "/Home", "/my", "/my/myMessage"];
      this.$router.push({
        path: pageArr[index]
      });
    },
    // 获取用户信息
    async getUserIndex() {
      let res = await this.$api.https.getUserIndex();
      if (!res.code) {
        this.$message({
          showClose: true,
          message: res.msg,
          type: "warning"
        });
        return;
      }
      this.$store.state.vuex_user = res.data.userInfo || {};
    },
    // 获取followPubchem信息
    async followPubchem() {
      try {
        let params = {
          type: "followPubchem"
        };
        let res = await this.$api.https.getblocklist(params);
        console.log(res.data);
        if (!res.code) {
          this.$message({
            message: res.msg
          });
          return;
        }
        this.followPubchemList = res.data;
      } catch (err) {
        console.log(err);
      }
    }
  },
  computed: {
    activeIndex() {
      return this.$store.state.activeIndex;
    },
    footBarShow() {
      let arr = this.$store.state.footerBarArr;
      console.log(this.$route.name);
      let show = arr.some(item => {
        return item == this.$route.name;
      });
      return show;
    },
    isLogin() {
      console.log("登录状态", this.$store.state);
      return Boolean(this.$store.state.vuex_token);
    },
    vuex_user() {
      console.log(this.$store.state.vuex_user);
      return this.$store.state.vuex_user;
    },
    vuex_token() {
      return this.$store.state.vuex_token;
    },
    indexData() {
      return this.i18n["index"];
    },
    // 全名
    vuex_config() {
      return this.$store.state.vuex_config;
    },
    nowPage() {
      let nowPage = "";
      switch (this.$route.name) {
        case "login":
          nowPage = 1;
          break;
        case "register":
          nowPage = 2;
          break;
        case "myMessage":
          nowPage = 3;
          break;
        case "myMessageInfo":
          nowPage = 3;
          break;
        default:
          break;
      }
      return nowPage;
    }
  },
  watch: {
    // 监测store.state
    vuex_token(newVal, oldVal) {
      console.log("vuex_token发生改变");
      if (newVal) {
        this.getUserIndex();
      }
    }
  }
};
</script>
<style lang="scss" scoped>
$vue_color: #0071bc;
.index {
  background-color: #f7f8f9;
  min-height: 100vh;
  .loginBar {
    height: 100px;
    background-color: $vue_color;
    display: flex;
    justify-content: center;
    .loginBox {
      width: 960px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .loginBox_left {
        margin: 0 0 0 74px;
        width: 100px;
        height: 100px;
        background-image: url("/img/home/pku_logo2.png");
        background-size: 100% 100%;
      }
      .loginBox_right {
        margin: 0 0 0 20px;
        flex: 1;
        display: flex;
        flex-direction: column;
        color: #ffffff;
        text-align: left;
        span:nth-child(1) {
          font-size: 26px;
          line-height: 40px;
        }
        span:nth-child(2) {
          opacity: 0.9;
        }
        .loginView {
          display: flex;
          align-items: center;
          .signBox {
            .sign {
              width: 70px;
              height: 24px;
              padding: 0;
              border-radius: 5px;
            }
            .sign:nth-child(1) {
              margin: 0 10px 0 0;
            }
            .el-button {
              color: $vue_color;
              border: none;
              border-radius: 4px;
            }
            .el-button.active,
            .el-button:focus,
            .el-button:hover {
              color: $vue_color;
              border-color: $vue_color;
              background-color: #f5d6d6;
            }
          }
        }
        .cande {
          margin: 0 0 0 28px;
          width: 28px;
          height: 28px;
          background-size: 28px 28px;
          transition: 0.5s;
          cursor: pointer;
        }
        .en-US {
          background-image: url("/img/en-US.png");
        }
        .zh-CN {
          background-image: url("/img/zh-CN.png");
        }
      }
    }
  }
  .tabbarBox {
    z-index: 10;
    display: flex;
    position: sticky;
    top: 0px;
    justify-content: center;
    background-color: #fff;
    border-top: 1px solid #eeeeee;
    border-bottom: 1px solid #eeeeee;
    .tabbarFlex {
      width: 960px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .tabbarLeft {
        margin: 0 15px 0 30px;
        width: 250px;
        height: 25px;
        background-image: url("/img/pku_logo2.png");
        background-size: 100% 100%;
      }
      .tabbarRight {
        flex: 1;
        text-align: right;
        justify-content: right;
        > span {
          color: #0495f3;
          cursor: pointer;
          margin-right: 0.5rem;
        }
        > span:nth-child(2) {
          margin-left: 0.5rem;
        }
      }
      .myInfo {
        display: flex;
        overflow: hidden;
        .notice {
          display: flex;
          flex-direction: column;
          justify-content: center;
          text-align: left;
          border-radius: 5px;
          cursor: pointer;
          transition: 0.5s;
          >span:nth-child(1){
            font-size: 15px;
            color: #282728;
          }
          >span:nth-child(2){
            font-size: 13px;
            color: #8F8F8F;
          }
        }
        .notice.active {
          background: #fff;
          color: $vue_color;
        }
        .myAvatar {
          margin: 0 6px 0 0;
          width: 44px;
          height: 44px;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            width: 100%;
            height: 100%;
            border-radius: 5px;
          }
        }
      }
      .tabbar {
        display: flex;
        justify-content: flex-end;
        font-weight: bold;
      }
      ::v-deep .el-menu-demo > .el-menu-item {
        display: flex;
        flex-direction: column;
        line-height: 20px;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        span:nth-child(1) {
          font-size: 14px;
          margin-top: 4px;
        }
        border: none !important;
      }
    }
  }
  .routerBox {
    width: 100%;
    display: flex;
    justify-content: center;
    .routerView {
      width: 960px;
    }
  }
  ::v-deep .el-backtop {
    color: $vue_color;
    z-index: 2001;
    font-size: 26px;
    box-shadow: 0 0 6px #0071bc50;
  }
  .drawer {
    display: flex;
    ::v-deep .drawerBox {
      display: flex;
      width: 100%;
      height: 100%;
      align-items: center;
      justify-content: center;
      .left {
        margin: 0 40px 0 0;
        width: 300px;
        height: 30px;
        background-image: url("/img/pku_logo2.png");
        background-size: 100% 100%;
      }
      .right {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        font-size: 20px;
        color: $vue_color;
      }
    }
  }
}
</style>
