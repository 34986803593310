<template>
  <div class="footerBar">
    <div class="topBox" v-if="false">
      <div class="top">
        <span>{{ footerData.title }}{{ footerData.Abbreviation }}</span>
      </div>
      <div class="contact">
        <span>
          <i class="el-icon-message"></i>
          <span>{{ email || "wen.liu@pku.edu.cn" }}</span>
        </span>
        <div class="line"></div>
        <span>
          <i class="el-icon-phone-outline"></i>
          <span>{{ phone || "010-62000000" }}</span>
        </span>
      </div>
      <div class="btn">
        <el-button @click="contentus">{{ footerData.contentus }}</el-button>
      </div>
    </div>
    <div class="bottomBox">
      <div class="menu">
        <div class="left_menu">
          <div class="title">
            <span>{{ title }}</span>
            <span>{{ titleLevel2 }}</span>
          </div>
          <div v-for="(item, index) in bottomtopinfo" :key="index">
            <div class="titleTip">
              {{ item.title }}
              <!-- {{ footerData.content }} -->
            </div>
            <div class="select" v-html="item.content">
              <!-- <p>{{ footerData.type[0] }}</p>
              <p>{{ footerData.type[1] }}</p>
              <p>{{ footerData.type[2] }}</p> -->
            </div>
          </div>

          <div class="line"></div>
          <div
            class="ullist"
            v-for="(item, index) in bottominfo"
            :index="index"
            :key="item"
            v-html="item"
          ></div>
          <!-- <div
            class="ullist"
            v-for="(item, index) in bottominfo"
            :index="index"
            :key="item.id"
            v-html="item.content"
          >
            <p>{{ footerData.unit[0] }}</p>
            <p>{{ footerData.unit[1] }}</p>
            <p>{{ footerData.unit[2] }}</p>
            <p>{{ footerData.unit[3] }}</p>
          </div> -->
        </div>
        <div class="right_menu">
          <div class="fllowTitle" @click="toDatabase">{{ fllowTitle }}</div>
        </div>
      </div>
      <div class="copyright">
        <span><el-link href="https://beian.miit.gov.cn" target="_blank" :underline="false">{{ record || footerTerms }}</el-link></span>
        <!-- <span v-for="(item, index) in copyright" :key="index"><el-link :href="item.url" target="_blank" :underline="false">{{
                item.title
              }}</el-link></span> -->
        <!-- <span>{{ footerData.terms[0] }}</span>
        <div class="line"></div>
        <span>{{ footerData.terms[1] }}</span>
        <div class="line"></div>
        <span>{{ footerData.terms[2] }}</span>
        <div class="line"></div>
        <span>{{ footerData.terms[3] }}</span> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "footerBar",
  props: {
    followPubchemList: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data() {
    return {
      title: "北京大学深圳研究生院环境材料化学实验室",
      titleLevel2: "深圳市南山区西丽大学城北京大学深圳研究生院",
      fllowTitle: "关于数据库",
      footerTerms: "所有权归“北京大学深圳研究生院环境材料化学实验室”所有",
      bottomtopinfo: [],
      bottominfo: ["北京大学", "北京大学深圳研究生院"],
      copyright: []
    };
  },
  mounted() {
    this.getAbout();
  },
  computed: {
    footerData() {
      return this.i18n["footerBar"];
    },
    phone() {
      if (this.$store.state.vuex_config.config) {
        return this.$store.state.vuex_config.config.phone_number;
      }
      return false;
    },
    email() {
      if (this.$store.state.vuex_config.config) {
        return this.$store.state.vuex_config.config.mail;
      }
      return false;
    },
    record() {
      if (this.$store.state.vuex_config.config) {
        return this.$store.state.vuex_config.config.beian;
      }
      return false;
    }
  },
  methods: {
    contentus() {
      this.$emit("drawerFn", true);
    },
    // 获取pcBottom信息
    async getAbout() {
      // 获取左边横排列表
      // this.bottomtopinfo = await this.getblocklist("bottomtopinfo");
      // 获取左边列表
      // this.bottominfo = await this.getblocklist("bottominfo");
      // 获取底部copyright
      // this.copyright = await this.getblocklist("copyright");
    },
    toDatabase() {
      this.$router.push({
        path: "/database"
      });
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
$vue_color: #6c7b90;
@mixin line() {
  .line {
    width: 1px;
    height: 16px;
    background-color: #ffffff;
    margin: 0 20px;
  }
}
@mixin title() {
  .title {
    display: flex;
    flex-direction: column;
    span:nth-child(1) {
      font-size: 22px;
    }
    span:nth-child(2) {
      margin-top: 6px;
      font-size: 12px;
    }
  }
}
.footerBar {
  font-size: 9px;
  color: #ffffff;
  .topBox {
    height: 278px;
    background-color: $vue_color;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .top {
      font-size: 20px;
      font-weight: bold;
    }
    .contact {
      padding: 30px 0;
      display: flex;
      align-items: center;
      font-size: 16px;
      color: #ffffffcc;
      @include line;
      span {
        display: flex;
        align-items: center;
        i {
          font-size: 24px;
          margin-right: 10px;
        }
      }
    }
    .btn {
      button {
        width: 250px;
        height: 45px;
        color: $vue_color;
        font-weight: bold;
        font-size: 15px;
        border-radius: 5px;
      }
    }
  }
  .bottomBox {
    height: 300px;
    background-color: #6c7b90;
    display: flex;
    box-sizing: border-box;
    padding: 32px 0 20px 0;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    align-items: center;
    .menu {
      flex: 1;
      display: flex;
      width: 960px;
      justify-content: space-between;
      .left_menu {
        flex: 1;
        display: flex;
        flex-direction: column;
        @include title;
        .titleTip {
          padding: 2px 0 16px 0;
        }
        .select {
          font-size: 12px;
          font-weight: bold;
          ::v-deep p {
            margin: 0 20px 0 0;
          }
        }
        .line {
          background-color: #626262;
          margin: 14px 0;
          width: 110px;
          height: 2px;
        }
        .ullist {
          display: flex;
          flex-direction: column;
          color: #ffffffcc;
          font-weight: bolder;
          font-size: 14px;
          margin-bottom: 6px;
          ::v-deep p {
            margin-bottom: 6px;
          }
        }
      }
      .right_menu {
        padding: 40px 80px 0 0;
        .fllowTitle {
          font-size: 18px;
        }
      }
    }
    .copyright {
      display: flex;
      justify-content: center;
      span {
        border-right: 1px solid #ffffff;
        padding: 0 20px;
      }
      ::v-deep .el-link--inner {
        color: #ffffff;
      }
      span:last-child {
        border: none;
      }
      @include line;
    }
  }
}
</style>
