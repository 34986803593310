import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

let lifeData = {};

try {
  // 尝试获取本地是否存在lifeData变量，第一次启动APP时是不存在的
  let getData = localStorage.getItem('lifeData');
  if (getData) {
    lifeData = JSON.parse(getData);
  }
  //   lifeData =localStorage.getItem('lifeData'); 
} catch (e) {}

// 需要永久存储，且下次打开需要取出的，在state中的变量名
let saveStateKeys = [
  'vuex_user',
  'vuex_token',
  'vuex_channel_id',
  'vuex_theme',
  'vuex_color',
  'vuex_route'
];

// 保存变量到本地存储中
const saveLifeData = function (key, value) {
  // 判断变量名是否在需要存储的数组中
  if (saveStateKeys.indexOf(key) != -1) {
    // 获取本地存储的lifeData对象，将变量添加到对象中
    let getLifeData = localStorage.getItem('lifeData');
    let tmp = {};
    if (getLifeData) {
      tmp = JSON.parse(getLifeData);
    }
    // 第一次打开APP，不存在lifeData变量，故放一个{}空对象
    tmp = tmp ? tmp : {};
    tmp[key] = value;
    // console.log('执行到这里了吗')
    // 执行这一步后，所有需要存储的变量，都挂载在本地的lifeData对象中
    localStorage.setItem('lifeData', JSON.stringify(tmp));
  }
}


console.log('lifeData', lifeData)


export default new Vuex.Store({
  state: {
    //动态设置title与metad的类metaInfo
    metaInfo: {
      title: "PKU-REOD首页",
      keywords: "PKU-REOD首页",
      description: "PKU-REOD首页"
    },
    vuex_user: lifeData.vuex_user ? lifeData.vuex_user : {},
    vuex_token: lifeData.vuex_token ? lifeData.vuex_token : '',
    vuex_channel_id: lifeData.vuex_channel_id ? lifeData.vuex_channel_id : '',
    vuex_config: lifeData.config ? lifeData.config : {},
    vuex_theme: lifeData.vuex_theme ? lifeData.vuex_theme : {},
    vuex__token__: '',
    activeIndex: '',
    myActiveIndex: '',
    // footerBarArr: ['changePswd', 'mySubmit', 'login', 'register', 'forgetpwd', 'mySublist'],
    footerBarArr: ['mySubmit', 'mySublist'],
    vuex_color: '#0495f3'
  },
  mutations: {
    $localStore(state, payload) {
      // 判断是否多层级调用，state中为对象存在的情况，诸如user.info.score = 1
      //   console.log('开始本地存储',state, payload)
      let nameArr = payload.name.split('.');
      let saveKey = '';
      let len = nameArr.length;
      if (len >= 2) {
        let obj = state[nameArr[0]];
        for (let i = 1; i < len - 1; i++) {
          obj = obj[nameArr[i]];
        }
        obj[nameArr[len - 1]] = payload.value;
        saveKey = nameArr[0];
      } else {
        // 单层级变量，在state就是一个普通变量的情况
        state[payload.name] = payload.value;
        saveKey = payload.name;
      }
      // 保存变量到本地，见顶部函数定义
      //   console.log('$localStore结束',saveKey, state[saveKey])
      saveLifeData(saveKey, state[saveKey])
    },
    CAHNGE_META_INFO(state, metaInfo) {
      state.metaInfo = metaInfo;
    }
  },
  actions: {},
  modules: {}
})
